@import "../../scss/normalize";
@import "../../scss/typography";
@import "../../scss/colors";
@import "../../scss/media";
.layout {
    height: 100vh;
    .body {
        height: calc(100% - 52px);
        display: flex;
        .lhs {
            flex-basis: 30%;
            max-width: 260px;
        }
        .rhs {
            flex-basis: 70%;
            flex-grow: 2;
        }
    }
}