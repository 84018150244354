@import "../../scss/normalize";
@import "../../scss/typography";
@import "../../scss/colors";
@import "../../scss/media";
.root {
    display: grid;
    place-items: center;
    height: 100vh;
    background: #f8f8f8;
    .container {
        padding: 100px;
        text-align: center;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
        .logo {
            object-fit: contain;
            height: 100px;
        }
        .button {
            margin-top: 50px;
            background-color: #0a8d48;
            color: #fff;
        }
    }
}