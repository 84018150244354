@import "../../../scss/normalize";
@import "../../../scss/typography";
@import "../../../scss/colors";
@import "../../../scss/media";
.root {
    display: grid;
    grid-template-columns: 1fr 20px;
    grid-column-gap: 10px;
    align-items: center;
    padding: 14px 20px;
    border-bottom: 1px solid lightgray;
    .lhs {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .nameBox {
            display: flex;
            align-items: center;
            .name {
                margin: 0;
                color: rgb(29, 28, 29);
                font-size: 15px;
                margin-right: 7px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            .star {
                cursor: pointer;
                height: 16px;
                width: 16px;
                &.selected {
                    fill: rgb(18, 100, 163);
                }
            }
        }
        .desc {
            color: #1D1C1DB3;
            font-size: 13px;
            line-height: 18px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    .rhs {
        .info {
            cursor: pointer;
            color:rgba(29, 28, 29, 0.7);
            width: 20px;
            height: 20px;
        }
    }
}