@import "../../../scss/normalize";
@import "../../../scss/typography";
@import "../../../scss/colors";
@import "../../../scss/media";
.item {
    height: 28px;
    line-height: 28px;
    color: rgb(207,195,207);
    font-size: 15px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &.selected {
        color: #fff;
        background-color: #1164A3;
    }
    &:hover {
        background-color: #350D36;
        opacity: 0.9;
    }
    .icon {
        width: 18px;
        height: 18px;
        margin-right: 8px;

    }
    .title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 18px;
        margin-bottom: 2px;
    }
}