@import "../../../scss/normalize";
@import "../../../scss/typography";
@import "../../../scss/colors";
@import "../../../scss/media";
.root {
    display: grid;
    grid-template-columns: 36px 1fr;
    grid-template-areas: "avatar usernameBox"
                         "avatar message";
    color: #1C1D1C;
    grid-column-gap: 8px;
    padding: 8px 20px;
    .avatar {
        grid-area: avatar;
        border-radius: 5px;
        height: 36px;
        width: 36px;
    }
    .usernameBox {
        grid-area: usernameBox;
        display: flex;
        align-items: center;
        .username {
            font-size: 15px;
            margin-right: 5px;
        }
        .timestamp {
            grid-area: timestamp;
            font-size: 12px;
            color: #606160;
        }
    }
    .message {
        grid-area: message;
        line-height: 1.46668;
        font-size: 15px;
    }
}